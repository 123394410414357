import styled from '@emotion/styled';
import { mq } from '~/lib';

export const CardCarouselLoader = styled.div({
    minHeight: 620,
    [mq(0, 'sm')]: {
        minHeight: 616,
    },
    [mq('lg')]: {
        minHeight: 660,
    },
});
